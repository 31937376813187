<template>
  <div>
    <v-row>
      <v-col align="center">
        <v-img :src="getAssetImg('/assets/logo-color-crop_wcl7z9.webp')" max-width="400" class="mt-8"></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="center">
        <p class="text-h3">
          About Us
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="10" offset="1" lg="8" offset-lg="2">
        <v-card light>
          <v-container fill-height>
            <v-row justify="center" align="center">
              <v-col cols="12" lg="8">
                <v-card-text>
                  <p class="text-h5">
                    Here at Premium Quality Drywall we are a small family owned company with big attributes. I've been
                    working in this trade for over 30 years and I truly love what I do. The fact that we are a family
                    owned company allows us to provide quality work for exceptional prices.
                  </p>
                </v-card-text>
              </v-col>
              <v-col cols="12" lg="4">
                <v-img :src="getAssetImg('/assets/family_y8viwj.webp')" contain max-height="400"></v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="10" lg="8" offset-lg="1">
        <v-card light>
          <v-container fill-height>
            <v-row justify="center" align="center">
              <v-col cols="12" lg="8">
                <v-card-text>
                  <p class="text-h5">
                    So why should YOU choose our company?
                    We like to treat all our customers like family and we do our job as if we were enhancing our own
                    home. We have customers that have been with us for years and they keep calling us back when they
                    need anything else done. Not only because we can do any job big or small but because we develop a
                    lasting business relationship.
                  </p>
                </v-card-text>
              </v-col>
              <v-col cols="12" lg="4">
                <v-img :src="getAssetImg('/assets/processwork_xiu85g.webp')" contain max-height="400"></v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="10" offset="2" lg="8" offset-lg="3">
        <v-card light>
          <v-container fill-height>
            <v-row justify="center" align="center">
              <v-col cols="12" lg="8">
                <v-card-text>
                  <p class="text-h5">
                    What ultimately drives us is your satisfaction so we will do whatever it takes to provide the most
                    excellent work possible at competitive prices. So join the family and give us a call for a free
                    estimate.
                  </p>
                </v-card-text>
              </v-col>
              <v-col cols="12" lg="4">
                <v-img :src="getAssetImg('/assets/putty_a3hpgd.webp')" contain max-height="400"></v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="10" offset="1" lg="8" offset-lg="2">
        <v-card light>
          <v-container fill-height>
            <v-row justify="center" align="center">
              <v-col cols="8" align="center">
                <v-card-text>
                  <p class="text-h5">
                    We are a member of the Contractors State License Board
                  </p>
                  <p class="text-h6">
                    <router-link :to="{name: 'licenses'}">Click here to check our License and Bonds</router-link>
                  </p>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  components: {},
  name: "AboutView",
  metaInfo: {
    title: 'About Us',
    link: [{rel: 'canonical', href: 'https://www.premiumqualitydrywall.com/about'}]
  },
  data() {
    return {}
  },
};
</script>

<style scoped>
</style>
